<template>
  <b-modal
    id="modal-create-contract"
    ref="modal-create-contract"
    centered="centered"
    :title="(contract.id ? 'Modifier' : 'Ajouter') + ` un type de contrat`"
    @ok="create"
  >
    <div v-if="isCreatingContract">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateContract">
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider #default="{ errors }" name="Libellé" rules="required">
              <b-form-input
                id="label"
                v-model="contract.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button class="float-right" variant="primary" @click="ok()">{{ contract.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      contract: {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
    };
  },
  methods: {
    popupCreateContractEvent(id) {
      if (id > 0) {
        this.fetchContract(id).then((res) => {
          this.contract = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.contract = {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    create(bvModalEvt) {
      if (bvModalEvt) bvModalEvt.preventDefault();
      this.$refs.formCreateContract.validate().then((success1) => {
        if (success1) {
          if (this.contract.id) this.updateContract(this.contract);
          else this.createContract(this.contract);
          this.$nextTick(() => {
            this.$refs["modal-create-contract"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createContract", "updateContract", "fetchContract"]),
  },
  computed: {
    ...mapGetters(["isCreatingContract"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
